import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    // console.log("window.innerWidth", window.innerWidth);
    // console.log("pageHeader", pageHeader);
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        // pageHeader.current.style.transform =
        //   "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/delicannaise/person-holding-bowl-2403392.jpg") +
            ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <img
                style={{ maxHeight: 400, maxWidth: "auto" }}
                className="img-rounded img-responsive"
                src={require("assets/img/delicannaise/LogoWhite.svg")}
              />
              <h3 className="description">The perfect match.</h3>
              <br />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
