import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components

function WhiteNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });

  //smooth scroll
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    var isMobile = navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (isMobile) {
      window.location.href = `https://delicannaise.co.za/product#${target}`;
      // window.location.href = `http://localhost:3000/product#${target}`;
      // if we are on mobile device the scroll into view will be managed by the browser
    } else {
      if (e) e.preventDefault();
      var targetScroll = document.getElementById(target);
      console.log("targetScroll", targetScroll);
      if (targetScroll)
        scrollGo(
          document.documentElement,
          targetScroll.offsetTop + window.innerHeight,
          1250
        );
      else {
        window.location.href = `https://delicannaise.co.za/product#${target}`;
        // window.location.href = `http://localhost:3000/product#${target}`;
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              id="navbar-brand"
              to="/index"
              tag={Link}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div style={{ width: 60 }}>
                <img
                  className="img-responsive"
                  src={require("assets/img/delicannaise/GrayKitchenIQLogo.svg")}
                />
              </div>
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              The perfect match.
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <Nav className="ml-auto" navbar>
                  <Link to={{}}>
                    <Button
                      className="btn-neutral"
                      color="success"
                      onClick={(e) => smoothScroll(e, "product")}
                    >
                      Product
                    </Button>
                  </Link>
                  <Link to={{}}>
                    <Button
                      className="btn-neutral"
                      color="success"
                      onClick={(e) => smoothScroll(e, "contact")}
                    >
                      Contact
                    </Button>
                  </Link>
                  <Link to="/about" onClick={() => window.scrollTo(0, 0)}>
                    <Button className="btn-neutral">About</Button>
                  </Link>
                </Nav>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
