/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";
// core components

function SectionIcons() {
  React.useEffect(() => {});
  const isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
  return (
    <>
      <div className="section section-icons" style={{}}>
        <Container fluid>
          <Row>
            {isMobile ? (
              <Col md="6" style={{ alignItems: "center", textAlign: "center" }}>
                <div>
                  <img
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                </div>
                <br />
              </Col>
            ) : (
              <Col md="3">
                <div className="icons-nucleo">
                  <img
                    className="first-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 65, maxWidth: 65 }}
                  />
                  <img
                    className="second-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 35, maxWidth: 35 }}
                  />
                  <img
                    className="third-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 25, maxWidth: 25 }}
                  />
                  <img
                    className="fourth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 50, maxWidth: 50 }}
                  />
                  <img
                    className="fifth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="sixth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="seventh-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 45, maxWidth: 45 }}
                  />
                  <img
                    className="eighth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="ninth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Basil.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="tenth-left-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Pepper.svg")}
                    style={{ maxHeight: 35, maxWidth: 35 }}
                  />
                </div>
              </Col>
            )}
            <Col className="text-center" md="6">
              <h4 className="info-title">It's personal</h4>
              <br />
              <h5 className="description" style={{ color: "black" }}>
                Knowing that quality and consistency are paramount,{" "}
                <Link
                  to="/about"
                  style={{ color: "#A62123" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  James
                </Link>{" "}
                is personally involved in the production process. Not a single
                bottle of Delicannaise gets onto the shelf without his
                experienced touch.
              </h5>
              <h4 className="info-title">Shelf life?</h4>
              <br />
              <h5 className="description" style={{ color: "black" }}>
                Unopened bottles of Delicannaise can be stored on the shelf in a
                cool environment for up to 6 months. Once opened it must be kept
                refrigerated and should be consumed within 1 month.
              </h5>
              <br />
            </Col>
            {isMobile ? (
              <Col md="6" style={{ alignItems: "center", textAlign: "center" }}>
                <div>
                  <img
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                  <img
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 25, maxWidth: 40 }}
                  />
                </div>
                <br />
              </Col>
            ) : (
              <Col md="3">
                <div className="icons-nucleo">
                  <img
                    className="first-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 65, maxWidth: 65 }}
                  />
                  <img
                    className="second-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 35, maxWidth: 35 }}
                  />
                  <img
                    className="third-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 25, maxWidth: 25 }}
                  />
                  <img
                    className="fourth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 50, maxWidth: 50 }}
                  />
                  <img
                    className="fifth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="sixth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="seventh-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 45, maxWidth: 45 }}
                  />
                  <img
                    className="eighth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="ninth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Honey.svg")}
                    style={{ maxHeight: 40, maxWidth: 40 }}
                  />
                  <img
                    className="tenth-right-icon nc-icon add-animation"
                    src={require("assets/img/delicannaise/icons/Mustard.svg")}
                    style={{ maxHeight: 35, maxWidth: 35 }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionIcons;
