import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

function AboutPage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <WhiteNavbar />
      <ProfilePageHeader />
      <div className="wrapper">
        <div
          className="profile-content section"
          style={{ backgroundColor: "#e8e8e8", color: "black" }}
        >
          <Container>
            <Row>
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding">
                    <img
                      alt="..."
                      src={require("assets/img/delicannaise/James.JPG")}
                    />
                  </div>

                  <div className="name">
                    <h4 className="title text-center">James Kearney</h4>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p>
                  Founded by chef James Kearney, Kitchen IQ is the result of a
                  desire to share a passion for food. In particular,
                  extraordinarily good food created to satisfy the palates of
                  discerning diners at 5-star South African lodges and hotels.
                  The African bush lodge environment ignited James’ creative
                  flair, resulting in many unique and outstanding dishes. James
                  felt some were simply too good not to make available more
                  widely, but how?
                </p>
                <br />
                <p>
                  Several of these specially developed items had an inherently
                  long shelf life. With further development they lend themselves
                  to being packaged for sale at specialty food outlets. James
                  set about his idea. In time, he started the company Kitchen IQ
                  (Pty) Ltd and gave up full time cheffing to realise his dream.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default AboutPage;
