/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer
        className="footer footer-white footer-gray"
        style={{ backgroundColor: "#E8E8E8" }}
      >
        <Container>
          <Row>
            <div className="credits ml-auto">
              <span className="copyright" style={{ color: "black" }}>
                © {new Date().getFullYear()} KitchenIQ , made with{" "}
                <i className="fa fa-heart" /> by{" "}
                <a
                  href="https://relimit.co.za"
                  target="_blank"
                  style={{ color: "#A62123" }}
                  onClick={() => console.log("clicked on RELIMIT")}
                >
                  <strong>relimit</strong>
                </a>
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
