import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function ProfilePageHeader() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/delicannaise/baking-cherry-tomatoes.jpg") +
            ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <img
                style={{ maxHeight: 150, maxWidth: "auto" }}
                className="img-rounded img-responsive"
                src={require("assets/img/delicannaise/KitchenIQWhite.svg")}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProfilePageHeader;
