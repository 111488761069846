import React from "react";
import axios from "axios";
// reactstrap components
import { Button, Form, Input, Container, Row, Col, Modal } from "reactstrap";

//route test
import { Link } from "react-router-dom";

// core components
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import SectionIcons from "../presentation-sections/SectionIcons.js";

import "./loadingStyle.css";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("presentation-page");
    window.addEventListener("scroll", checkScroll);
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("presentation-page");
      window.removeEventListener("scroll", checkScroll);
    };
  });

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //   };
  // });

  const checkScroll = () => {
    // it takes all the elements that have the .add-animation class on them
    const componentPosition = document.getElementsByClassName("add-animation");
    const scrollPosition = window.pageYOffset;
    for (var i = 0; i < componentPosition.length; i++) {
      var rec =
        componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
      // when the element with the .add-animation is in the scroll view,
      // the .animated class gets added to it, so it creates a nice fade in animation
      if (scrollPosition + window.innerHeight >= rec) {
        componentPosition[i].classList.add("animated");
        // when the element with the .add-animation is not in the scroll view,
        // the .animated class gets removed from it, so it creates a nice fade out animation
      } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
        componentPosition[i].classList.remove("animated");
      }
    }
  };

  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [modalFailure, setModalFailure] = React.useState(false);
  const [wait, setWait] = React.useState(false);
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleClick = async () => {
    setWait(true);
    // const result = { data: { result: false } };
    // console.log("success? ", result.data.result === true);
    console.log(
      "name",
      name,
      "phone",
      phone,
      "email",
      email,
      "message",
      message
    );

    const result = await axios
      .post(
        "https://rs821075gi.execute-api.eu-west-1.amazonaws.com/dev/relimit/send-email",
        {
          name,
          phone,
          email,
          message,
          to: "delicannaise",
        }
      )
      .catch((e) => {
        console.log(JSON.stringify(e));
        return false;
      });
    if (result && result.data.result === true) {
      setModalSuccess(true);
    } else {
      setModalFailure(true);
    }
    setWait(false);
  };
  return (
    <>
      <WhiteNavbar
      // section={"contact"}
      // {...props}
      />
      <LandingPageHeader />
      <div className="wrapper">
        <div
          className="section text-center landing-section"
          id="product"
          style={{ color: "black" }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h5>
                  Delicannaise is the culmination of a cheffing career most
                  would dream of, from five star bush lodges to à la carte
                  restaurants. It is a hand-made, true mayonnaise infused with
                  carefully selected additional ingredients. The result is not
                  just a flavoured mayonnaise, but complex flavour blends, a
                  uniquely different texture and luxurious mouth feel. Truly, a
                  culinary experience crafted for the most discerning.
                </h5>
                <br />
                <h5>
                  In his pursuit of flavour,{" "}
                  <Link
                    to="/about"
                    style={{ color: "#A62123" }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    James Kearney
                  </Link>{" "}
                  has chosen Delicannaise as the first of many products that
                  bring 5 star dining into your kitchen.
                </h5>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i
                      className="nc-icon nc-touch-id"
                      style={{ color: "#474440" }}
                    />
                  </div>
                  <div className="description" style={{ color: "black" }}>
                    <h4 className="info-title">Hand crafted</h4>
                    <p>
                      Each bottle of Delicannaise is bottled by hand, for
                      consistent product quality.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i
                      className="nc-icon nc-favourite-28"
                      style={{ color: "#A62123" }}
                    />
                  </div>
                  <div className="description" style={{ color: "black" }}>
                    <h4 className="info-title">Passionately created</h4>
                    <p>
                      A passion project that took over our lives, Delicannaise
                      was born from a desire to bring 5 star culinary
                      experiences into day-to-day living.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i
                      className="nc-icon nc-watch-time"
                      style={{ color: "#474440" }}
                    />
                  </div>
                  <div className="description" style={{ color: "black" }}>
                    <h4 className="info-title">Tried, and tried again</h4>
                    <p>
                      Development, refinement and testing has taken years.
                      Finally, we’re proud to present Delicannaise to the world.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section section-dark text-center landing-section"
          style={{ backgroundColor: "#E8E8E8", color: "black" }}
        >
          <Container>
            <h5>
              Although it can be used in all the same ways as a conventional
              mayonnaise, Delicannaise is more versatile. In addition to a
              dressing, you’ll find it a perfect match to meat, fish or cheese.
              Can you use it as a dip or spread? It’s an unbridled yes from us.{" "}
            </h5>
            <br />
            <h5>
              Delicannaise is currently available in two flavours, with more to
              follow:
            </h5>
            <br />
            <br />
            <img
              style={{ maxHeight: 300, maxWidth: "auto" }}
              className="img-rounded img-responsive"
              src={require("assets/img/delicannaise/1delicannaise.svg")}
            />
            <br />
            <img
              style={{ maxHeight: 300, maxWidth: "auto" }}
              className="img-rounded img-responsive"
              src={require("assets/img/delicannaise/2delicannaise.svg")}
            />
          </Container>
        </div>
        <SectionIcons />
        <div
          className="section landing-section"
          style={{ backgroundColor: "#E8E8E8", color: "black" }}
          id="contact"
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Contact</h2>
                <br />
                <h5 className="text-center">
                  Delicannaise is made available to consumers through local
                  specialty food outlets. The sales office is based in
                  Linksfield, Johannesburg. Courier shipments can be made to any
                  destination in South Africa. For orders or enquiries send us a
                  message as below, alternatively please contact{" "}
                  <a
                    href="mailto: val@kutha.co.za"
                    style={{ color: "#A62123" }}
                  >
                    val@kutha.co.za
                  </a>{" "}
                  or phone <nobr>011 485 1218.</nobr>
                </h5>
                <Form className="contact-form">
                  <Row>
                    <Col md="12">
                      <label>Name</label>
                      <Input
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <Input
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col md="6">
                      <label>Phone</label>
                      <Input
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Type your message here..."
                    type="textarea"
                    rows="4"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Row>
                    <Col className="offset-md-4" md="4">
                      <Button
                        className="btn-fill"
                        color="default"
                        size="lg"
                        onClick={() => handleClick()}
                      >
                        Send Message{"  "}
                        {wait && (
                          <i className="nc-icon nc-refresh-69 loading" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
          <Modal
            isOpen={modalSuccess}
            toggle={() => setModalSuccess(false)}
            style={{ color: "black" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                Message sent
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalSuccess(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>We will get back to you as soon as possible!</p>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-link"
                color="success"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalSuccess(false)}
              >
                Okay
              </Button>
            </div>
          </Modal>
          <Modal
            isOpen={modalFailure}
            toggle={() => setModalFailure(false)}
            style={{ color: "black" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLiveLabel">
                Message couldn't send
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalFailure(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Please try again later. Alternatively please contact{" "}
                <a href="mailto: val@kutha.co.za" style={{ color: "#A62123" }}>
                  val@kutha.co.za
                </a>{" "}
                or phone 011 485 1218.
              </p>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-link"
                color="success"
                data-dismiss="modal"
                type="button"
                onClick={() => setModalFailure(false)}
              >
                Okay
              </Button>
            </div>
          </Modal>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
